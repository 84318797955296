<template>
  <main>
    <ul class="c-nav c-nav--sub-nav o-list-inline">
      <li
        class="c-nav__list-item o-list-inline__item"
        v-for="subpage in subPages"
        :key="subpage.path"
      >
        <router-link class="c-nav__item" :to="`${rootPath}/${subpage.path}`">
          {{ subpage.name }} </router-link
        >&nbsp;
      </li>
    </ul>
    <section class="c-content">
      <h1 v-if="introPage" class="c-content__title">Fysieke Ontwikkeling</h1>
      <h2 v-if="introPage" class="c-content__title">{{ $route.name }}</h2>
      <h1 v-else class="c-content__title">{{ $route.name }}</h1>
      <router-view></router-view>

      <div v-if="introPage" class="c-content__body o-layout">
        <div class="o-layout__item c-content__text" v-html="htmlContent" />
        <div
          v-if="htmlContentReferences.length > 0"
          class="o-layout__item c-content__text"
        >
          <div class="c-content__text-ref" v-html="htmlContentReferences" />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import appRoutes from "@/appRoutes";
import content from "@/content";
export default {
  name: "PhysicalDevelopment",
  data() {
    return {
      htmlContent: "",
      htmlContentReferences: "",
      introPage: false
    };
  },
  mounted() {
    this.setContentText(0);
    this.introPage = this.isIntroPage();
  },
  methods: {
    setContentText(index) {
      // console.log(this.$route.name.toLowerCase());
      // console.log("this.pageType", this, index, this.subPages, this.rootPath);
      // console.log("content[this.pageType]", content[this.pageType]);
      content["physicalDevelopment"].intro.then(response => {
        if (response) {
          this.htmlContent = response.default.replaceAll(
            "/assets",
            `${this.$publicPath}/assets`
          );
          // console.log(response.default);
        }
      });

      content["physicalDevelopment"].references.then(response => {
        if (response) {
          this.htmlContentReferences = response.default.replaceAll(
            "/assets",
            `${this.$publicPath}/assets`
          );
          // console.log(response.default);
        }
      });
    },
    isIntroPage() {
      // console.log(this.$route.name.toLowerCase());
      // console.log(this.$route.path, this.rootPath);
      return this.$route.meta.id === "intro";
    }
  },
  computed: {
    rootPath() {
      return appRoutes[0].path;
    },
    subPages() {
      return appRoutes[0].children;
    }
  },
  watch: {
    $route(newValue, oldValue) {
      this.introPage = this.isIntroPage();
    }
  }
};
</script>

<style scoped></style>
